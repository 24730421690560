<template>
  <div>
    <!-- Queremos conectar las empreas -->
    <section id="we_cant_to_coneect" class="section-top-contend">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-sm-12 col-md-offset-1 text-left">
                    <div class="title" itemscope itemtype="https://handelbay.com/">
                        <h2 class="title-1" itemprop="HandelBay Innvador">Queremos conectar las empresas</h2>
                        <h3 class="title-2" itemprop="HandelBay en tu día a día">alrededor del mundo</h3>
                        <span class="handelbay-active"></span>
                    </div>
                </div>
            </div>
            <!-- .row -->
        </div>
        <!-- .container -->
    </section>
    <!-- End queremos conectar las empresas -->

    <!-- Por qué -->
    <section id="why_handelbay">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-sm-12 col-md-offset-1 z-index text-left">
                    <div class="title" itemscope itemtype="https://handelbay.com/">                        
                        <h2 class="title-2" itemprop="HandelBay en tu día a día">¿Qué nos motiva?</h2>
                        <span class="handelbay-active"></span>
                    </div>
                    <div class="handelbay-text" itemscope itemtype="https://handelbay.com/">
                        <p itemprop="HandelBay">
                            Nosotros estamos convencidos que el futuro del abastecimiento
                            está dado por la dinámica de comercio en línea, en el cual
                            la optimización, transparencia y agilidad son los parámetros
                            principales en los negocios empresariales.
                        </p>                       
                    </div>
                </div>
            </div>
            <!-- .row -->
            <div class="row">
                <div class="col-md-11 col-md-ofsset-1">
                    <div class="img_provider_and_buyer">
                        <img src="../assets/images/landing/handelbay/handelbay_proveedor_comprador.png" alt="Proveedor y comprador">
                    </div>                    
                </div>
            </div>
            <!-- .row -->

            <div class="row">
                <div class="col-md-6 col-sm-12 col-md-offset-5 z-index text-right text-bottom">
                    <div class="title" itemscope itemtype="https://handelbay.com/">                        
                        <h3 class="title-2" itemprop="HandelBay en tu día a día">¿Cómo lo hacemos?</h3>
                        <span class="handelbay-active"></span>
                    </div>
                    <div class="handelbay-text" itemscope itemtype="https://handelbay.com/">
                        <p itemprop="HandelBay">
                            A través de un diseño centrado en le usuario, mejores prácticas de desarrollo y la innovación permanente, creamos un sistema disponible en la nube (SaaS - Software as a Service) que reúne a compradores con proveedores a través de una red social y plataforma de compras, llamada Markley HandelBay.
                        </p>                       
                    </div>
                </div>
            </div>
             <!-- .row -->
        </div>
        <!-- .container -->
    </section>
    <!-- end por qué -->

    <!-- .handelbay-background-curva -->
    <section id="handelbay-background-curva">

    </section>
    <!-- .handelbay-background-curva -->

    <!-- Que hacemos -->
    <section id="what_do_we_do">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-sm-7 col-xs-12 col-md-offset-1 text-left">
                    <div class="title" itemscope itemtype="https://handelbay.com/">
                        <h2 class="title-2" itemprop="HandelBay en tu día a día">¿Qué hacemos?</h2>
                        <span class="handelbay-active"></span>
                    </div>
                    <div class="handelbay-text" itemscope itemtype="https://handelbay.com/">
                        <p itemprop="HandelBay es la solución">
                            Por medio de esta herramienta, y en función del tipo de perfil (comprador, proveedor o dual), las empresas pueden gestionar
                            internamente sus compras empresariales y publicar sus necesidades de compra, no sólo a sus proveedores
                            actuales sino también a una red en permanente crecimiento de proveedores a nivel nacional.
                        </p>
                        <p itemprop="HandelBay te permite optimizar">
                            Adicionalmente pueden participar y cotizar en los procesos de compra publicados por la red de compradores existentes en Markley HandelBay.
                        </p>

                        <p itemprop="HandelBay te permite optimizar" class="text-bold-handelbay">
                            <strong>
                                Markley HandelBay es una plataforma de compras empresariales (marketplace B2B) que conecta compradores potenciales con proveedores
                                competitivos a través de una red social empresarial.
                            </strong>
                        </p>
                    </div>
                </div>
            </div>
            <!-- .row -->
        </div>
        <!-- .container -->
    </section>
    <!-- End Que hacemos -->

    <!-- .handelbay-background-curva -->
    <section id="handelbay-background-curva-bottom">
    </section>
    <!-- .handelbay-background-curva -->

    <!-- Ignite -->
    <section id="ignite">
        <div class="container">
            <div class="row">
                <div class="col-md-10 col-sm-12 col-md-offset-1" itemscope>
                    <div class="title-ignite">
                        <div class="row">
                            <div class="col-md-6 col-md-offset-3 col-sm-6 col-sm-offset-3">
                                <a href="http://www.ignite.com.co" target="_blank">
                                    <img src="../assets/images/landing/handelbay/handelbay_ignite.png" alt="Ignite technologies">
                                </a>
                            </div>
                        </div>
                    </div>
                    <p itemprop="HandelBay diseño creado por Ignite Technologies S.A.S">
                        HandelBay es un producto diseñado y creado por Ignite Technologies S.A.S una empresa dedicada al diseño y desarrollo de productos de innovación en tecnología, ingeniería y ciencia.  Con el apoyo de Markley y PWA Consultores, ofrecemos una solución intregral al comercio electrónico B2B.
                    </p>
                    <div class="row">
                        <div class="col-md-4 col-sm-4 no-p-r no-border-ignite">
                            <div class="tecno-ignite text-center">
                                <div class="brand">
                                    <img src="../assets/images/landing/handelbay/handelbay_brand1.png" alt="Tecnología">
                                </div>
                                <h2 class="title">Tecnología</h2>
                                <p>
                                    Estamos convencidos que la tecnología es el camino más óptimo para que nuestros clientes alcancen mayor impacto en sus negocios
                                    en menos tiempo.
                                </p>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 no-p-r no-p-l no-border-ignite">
                            <div class="tecno-ignite text-center">
                                <div class="brand">
                                    <img src="../assets/images/landing/handelbay/handelbay_brand2.png" alt="Tecnología">
                                </div>
                                <h2 class="title">Ingeniería</h2>
                                <p>
                                    Es nuestra herramienta principal para afrontar los problemas y necesidades de nuestros clientes y poder diseñar y entregar
                                    las mejores soluciones.
                                </p>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 no-p-l no-border-ignite">
                            <div class="tecno-ignite text-center">
                                <div class="brand">
                                    <img src="../assets/images/landing/handelbay/handelbay_brand3.png" alt="Tecnología">
                                </div>
                                <h2 class="title">Ciencia</h2>
                                <p>
                                    Articulamos las competencias de nuestro equipo para diseñar soluciones que permitan racionalizar el uso de los recursos naturales renovables, sustituyéndolos con sistemas o recursos
                                    alternativos.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- .row -->
        </div>
        <!-- .container -->

    </section>
    <!-- End Ignite -->
    <PorqueHandelbay />

  </div>
</template>
<script>
import PorqueHandelbay from '@/components/PorqueHandelbay'

export default {
    components:{
        PorqueHandelbay: PorqueHandelbay
    }
}
</script>
